import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import MainMenu from "./mainMenu"
import Headroom from "react-headroom"

const Header = ({ siteTitle, siteDescription }) => (
  <Headroom>
  <header className="bg-gray-white">
    <div className="flex flex-wrap items-center justify-between max-w-6xl mx-auto p-4 md:p-8">
      <Link className="flex items-center no-underline text-gray-800" to="/">
        <svg
          className="fill-current h-8 mr-2 w-8"
          height="54"
          viewBox="0 0 54 54"
          width="54"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z" />
        </svg>
        <span className="font-bold text-2xl md:text-4xl">
          {siteTitle}
        </span>
      </Link>
      <MainMenu />
    </div>
  </header>
  </Headroom>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,

}

export default Header
