import React, { useState }  from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { createLocalLink } from "../utils"

const MAIN_MENU_QUERY = graphql`
  fragment MenuFields on WPGraphql_MenuItem {
    id
    label
    url
  }

  query GET_MENU_ITEMS {
    wpgraphql {
      menuItems(where: { location: PRIMARY }) {
        nodes {
          ...MenuFields
          childItems {
            nodes {
              ...MenuFields
            }
          }
        }
      }
    }
  }
`

const renderMenuItem = item => {
  let hasChild = false
  if (item.childItems && item.childItems.nodes.length) {
    hasChild = true
  }
  return (
      <Link key={item.id} className="block md:inline-block mt-4 md:mt-0 md:ml-2 no-underline text-gray-800 hover:bg-gray-300 px-6 py-3 rounded uppercase" to={createLocalLink(item.url)}>{item.label}</Link>
  )
}

const MainMenu = (props) => {
  const [isExpanded, toggleExpansion] = useState(false);
  return (
    <StaticQuery
      query={MAIN_MENU_QUERY}
      render={({
        wpgraphql: {
          menuItems: { nodes: menu },
        },
      }) => {
        return (
          <>
          <button
            className="block md:hidden text-white"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="fill-current h-8 w-8"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
             <path d="M0,0 L20,0 L20,3 L0,3 L0,0 Z M0,6.5 L20,6.5 L20,9.5 L0,9.5 L0,6.5 Z M0,13 L20,13 L20,16 L0,16 L0,13 Z" id="Shape"></path>
            </svg>
          </button>
          <nav
          className={`${
            isExpanded ? `block` : `hidden`
            } md:block md:flex md:items-center w-full md:w-auto main-menu`}
          >
            {menu.map(item => renderMenuItem(item))}
          </nav>
          </>
        )
      }}
    />
  )
}

export default MainMenu
